import { useEffect, useMemo, useRef } from "react";
import skale from "../../../core/networks/evm-networks/skale";
import { isTestnet } from "../../../core/isTesnet";
import { useEthTokenBalance } from "../../../modules/Tokens";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../modules/Network";

export const useSkaleFuelBalance = () => {
  const skaleNative = useMemo(() => skale.customEth(isTestnet), []);
  const { address } = useWeb3ModalAccount();
  const { network } = useNetworkContext();
  const { balance: skaleNativeBalance } = useEthTokenBalance(skaleNative);
  const prevBalance = useRef(skaleNativeBalance);
  const isShowFuel =
    address && prevBalance.current === "0" && network === "skale";
  useEffect(() => {
    if (
      skaleNativeBalance !== "0" &&
      skaleNativeBalance !== prevBalance.current
    ) {
      prevBalance.current = skaleNativeBalance;
    }
  }, [skaleNativeBalance]);
  return {
    skaleNativeBalance: prevBalance.current,
    skaleNative,
    isShowFuel,
  };
};
